import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/about-about_sec_img.png'


const _hoisted_1 = { class: "about_outer about_section space" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row align-items-center" }
const _hoisted_4 = { class: "col-md-6" }
const _hoisted_5 = { class: "about_content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "col-md-6" }, [
          _createElementVNode("div", { class: "about_img" }, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: ""
            })
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "tittle_cm" }, [
              _createTextVNode(" Discover Our "),
              _createElementVNode("span", null, "Story"),
              _createElementVNode("br"),
              _createTextVNode(" and Get in Touch With "),
              _createElementVNode("span", null, "Us")
            ], -1)),
            _cache[2] || (_cache[2] = _createElementVNode("p", { class: "mt-3 mb-4" }, " TopPick is realized by a team of talented people working tirelessly to give you the best experience possible. Discover our story, goals, and why we believe this platform will prove itself useful to everyone who wants to take their communication skills to the next level. ", -1)),
            _createVNode(_component_router_link, {
              class: "cm-btn cm_bg",
              to: { name: 'ContactUs' }
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Contact us"),
                _createElementVNode("i", {
                  class: "fa fa-long-arrow-right ml-2",
                  "aria-hidden": "true"
                }, null, -1)
              ])),
              _: 1
            })
          ])
        ])
      ])
    ])
  ]))
}